.user-profile-header-banner img {
   
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 250px;
}

.user-profile-header {
    margin-top: -2rem;
}
.profile-nav, .profile-info{
    margin-top:30px;   
}
.user-profile-header .user-profile-img {
    border: 5px solid;
    width: 120px;
}

span.select-display{
    position:absolute;
   
}